<template>
  <div class="esign-content">
    <h1>E-Sign Here</h1>
    <div>
      <vueSignature style="border: 1px solid black;" ref="signature" :sigOption="option" :w="'260px'" :h="'200px'" :disabled="disabled"></vueSignature>  
      <v-btn color="success" @click="save" :loading="saveLoading" :disabled="saveLoading">
        Save
      </v-btn>
      <v-btn outlined @click="clear">
        Clear
      </v-btn>
    </div>
  </div>
</template>

<script>
import vueSignature from "vue-signature"

export default {
  name: 's-operations',
  props: {
    saveLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    vueSignature
  },
  data() {
    return {
      option:{
        penColor:"rgb(0, 0, 0)",
        backgroundColor:"rgb(255,255,255)"
      },
      disabled:false
    };
  },
  methods:{
    save(){
      var _this = this;
      if (_this.$refs.signature.isEmpty()) {
        this.$store.dispatch('setAlert', {
          status: 'error',
          show: true,
          message: 'E-sign is mandatory to submit job',
        });
        return;
      }
      var png = _this.$refs.signature.save();
      this.$emit('saveSign', png);
    },
    clear(){
      var _this = this;
      _this.$refs.signature.clear();
    },
    undo(){
      var _this = this;
      _this.$refs.signature.undo();
    },
  }
};
</script>

<style scoped>
button {
  border: 1px solid black;
  padding: 2px 12px;
  margin: 6px 15px;
  border-radius: 7px;
}
.esign-content {
  display: flex;
  flex-flow: column;
  align-items: center;
}
</style>