<template>
  <v-container fluid>
    <v-row :align="'center'" :justify="'start'">
      <v-col class="col-xs-12 col-sm-4 col-md-4">
        <v-img
          :src="profileImg"
          class="my-3 mx-3"
          contain
          height="250"
        />
      </v-col>
      <v-col class="col-xs-12 col-sm-6 col-md-4">
        <table class="w-full">
          <tr v-for="(key, index) in Object.keys(profile)" :key="index" v-show="profile[key]">
            <td class="font-weight-bold blue-grey--text text--darken-3 pr-2 pb-4 text-capitalize">{{key.split('_').join(' ')}}</td>
            <td class="pl-2 blue-grey--text text--darken-3 pb-4">{{profile[key]}}</td>
          </tr>
        </table>
        <div v-if="profile">
          <v-btn color="primary" @click="dialogForm = {action: 'Edit User', showForm: true, user: Object.assign(profile)}">Edit Profile</v-btn>
          <v-btn color="primary" @click="dialogForm = {action: 'Update Password', showForm: true, user: Object.assign(profile)}">Update Password</v-btn>
        </div>
      </v-col>
    </v-row>
    <user-form 
      :user="dialogForm.user"
      :action="dialogForm.action"
      :showForm="dialogForm.showForm"
      @formSubmitted="resetDialog();getProfile();"
      @closeDialog="resetDialog()" />
  </v-container>
</template>

<script>
  import profileImg from 'images/profile.png';
  import UserForm from './blocks/UserForm';

  export default {
    name: 's-profile',
    components: {
      UserForm,
    },
    computed: {
      profile: vueInstance => vueInstance.$store.state.auth.profile,
    },
    data() {
      return {
        profileImg,
        dialogForm: {
          showForm: false,
          action: '',
          user: {},
        },
      };
    },
    methods: {
      resetDialog() {
        this.dialogForm = {showForm: false, action: '', user: {}};
      },
      getProfile() {
        this.$api.users.getProfile()
          .then((response) => {
            this.$store.dispatch('auth/setProfile', response.profile);
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
    },
  };
</script>