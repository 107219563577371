import axios from 'axios';

const http = axios.create({
  baseURL: '/api/v1',
});

const csrfToken = document.querySelector("meta[name=csrf-token]").content;

http.defaults.headers.common['X-CSRF-Token'] = csrfToken;

/* Response Interceptors */
const interceptResErrors = (err) => {
  try {
    err = Object.assign(new Error(), {message: err.response.data.error});
  } catch (e) {
    // Will return err if something goes wrong
  }
  return Promise.reject(err);
};
const interceptResponse = (res) => {
  try {
    return Promise.resolve(res.data.result);
  } catch (e) {
    return Promise.resolve(res);
  }
};
http.interceptors.response.use(interceptResponse, interceptResErrors);

/* Request Interceptors */
const interceptReqErrors = err => Promise.reject(err);
const interceptRequest = (config) => {
  return config;
};
http.interceptors.request.use(interceptRequest, interceptReqErrors);

export default http;