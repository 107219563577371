<template>
	<v-container fluid>
		<v-card>
			<v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
      <template v-slot:item.folder="{ item }">
          <v-btn @click="$router.push({
          	name: 'operations',
          	query: {
          		folderName:item.folder
          	}
          });">
            <span><u>{{item.folder}}</u></span>
          </v-btn>
        </template>
      </v-data-table>
		</v-card>
	</v-container>
</template>

<script>
	export default {
		name: 's-folders',
		data() {
			return {
        search: '',
        loading: false,
				headers: [
          {text: 'Folder', value: 'folder', align: 'center'},
          {text: 'Vessel', value: 'vessel', align: 'center'},
          {text: 'Driver', value: 'driver', align: 'center'},
          {text: 'Customer', value: 'customer_name', align: 'center'},
         ],
        items: [],
        folder:'',
			};
		},
		methods: {
			getAllFolders(){
				this.loading = true;
        this.$api.operations.getFolders()
          .then((response) => {
          	this.items = response.folders
          }).catch((err) => {0
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
			}
		},
		created() {
			this.getAllFolders();
		},
	}
</script>