import http from '../axios';

export function index(filters) {
  return http.get('/operations', {
    params: filters
  });
}

export function getJobDetails(jobNumber) {
  return http.get('/operations/get_job_details', {
    params: {
      job_number: jobNumber
    }
  });
}

export function getFolderDetails() {
  return http.get('/operations/get_folder_details');
}

export function getFolders(){
  return http.get('/operations/get_folders');
}

export function create(payload){
  return http.post('/operations', payload);
}

export function update(payload) {
  return http.patch(`/operations/${payload.id}`, payload);
}