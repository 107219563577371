<template>
  <v-app-bar fixed color="primary" class="white--text">
    <v-app-bar-nav-icon>
      <template slot>
        <v-icon color="white" class="white--text" 
          @click="handleHomePage"
          :disabled="$route.name == defaultHomePage">mdi-home</v-icon>
      </template>
    </v-app-bar-nav-icon>
    <v-toolbar-title class="text-capitalize">{{toolbarTitle}}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="white">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list class="side-menu">
        <v-list-item-group>
          <v-list-item @click="$router.push({name: 'profile'})">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{$store.state.auth.profile.username}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 's-nav-appbar',
    data() {
      return {
        defaultHomePage: 'dashboard'
      }
    },
    computed: {
      toolbarTitle() {
        return this.$root.pageTitle || this.$route?.name?.split('-')?.join(' ');
      },
    },
    methods: {
      handleHomePage() {
        if (this.$store.state.auth.profile.role === 'admin') {
          this.$router.push({name: this.defaultHomePage});
        } else {
          this.$router.push({name: this.defaultHomePage, params: {job_number: 'new'}});
        }
      },
      logout() {
        this.$api.sessions.logout()
          .then((response) => {
            Promise.all([
              this.$store.dispatch('auth/setProfile', {}),
              this.$store.dispatch('auth/setIsAuthChecked', true),
            ]).then(() => {
                this.$store.dispatch('setAlert', {
                  status: 'success',
                  show: true,
                  message: 'Logged out successfully',
                });
                this.$router.push({name: 'login'});
              });
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
    },
    created() {
      if (!(this.$store.state.auth.profile.role === 'admin')) {
        this.defaultHomePage = 'operation-show';
      }
    },
  };
</script>

<style scoped>
  .side-menu {
    min-width: 170px;
  }
</style>