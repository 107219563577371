<template>
  <div class="text-center">
    <v-dialog v-model="showForm" width="500" persistent>
      <v-card class="card">
        <v-card-title class="text-h5 grey lighten-2">
          {{action}}
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('closeDialog');clearJob();">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="form" v-model="form" @submit.prevent="submit">
          <v-card-text class="pb-0">
            <v-text-field
              label="Job Number"
              placeholder="Enter Job Number"
              outlined
              dense
              type="text"
              v-model="jobNumber"
              prepend-inner-icon="mdi-magnify"
              append-icon="mdi-send"
              @click:append="searchJob"
              :disabled="jobField"
              clearable
            ></v-text-field>
          </v-card-text>
          <v-row class="text-left row">
            <v-col cols="12" sm="6" class="d-flex" 
              v-for="(item, index) in Object.keys(jobDetails)"
              :key="index"
            >
              <v-row class="job-row__border ma-1 align-center">
                <v-col cols="6" class="text-capitalize primary--text subtitle-2 font-weight-bold py-0">
                  {{item}}
                </v-col>
                <v-col cols="6" class="subtitle-2 py-0">
                  {{jobDetails[item]}}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="row" v-if="clone">
            <v-col>
              <v-divider></v-divider>
              <v-card-actions class="justify-center">
                <v-btn color="primary" outlined @click="saveJob()" 
                  :disabled="loading" :loading="loading">
                  Clone To Job
                </v-btn>
                <v-btn color="primary" outlined @click="clearJob()">
                  Clear
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import rules from '../../../utilities/validator';
  import Loading from '../../shared/Loading';
  import axios from 'axios';

  export default {
    name: 's-clone-form',
    props: {
      action: {
        type: String,
        required: true,
      },
      showForm: {
        type: Boolean,
        default: false,
      },
      clonedJob: {
        type: Object,
        default: {},
      },
    },
    data() {
      return {
        form: false,
        jobField: false,
        rules,
        jobNumber: '',
        operation: {},
        jobDetails: {},
        cloneDetails: {},
        clone: false,
        loading: false,
        saveLoading: false,
      };
    },
    methods: {
      saveJob() {
        this.saveLoading = true;
        this.$api.operations.create({
          job_number: this.jobNumber,
          e_sign: this.cloneDetails.eSign,
          remarks: this.cloneDetails.remarks,
          rank: this.cloneDetails.rank,
          end_time: this.cloneDetails.time,
          signee_name: this.cloneDetails.signeeName,
          driver: this.jobDetails.driver,
          agent_name: this.jobDetails.agent,
          customer_name: this.jobDetails.customer,
          vessel: this.jobDetails.vessel,
          pax: this.jobDetails.pax,
          folder: this.cloneDetails.folder
        })
          .then(response => {
            this.$store.dispatch('setAlert', {
              show: true,
              message: 'Job Saved successfully',
            })
            if (this.$store.state.auth.profile.role === 'admin') {
              this.$router.push({name: 'operations'});
            }
          })
          .catch(err => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            })
          }).finally(() => (this.saveLoading = false));
      },
      clearJob () {
        this.jobNumber = '';
        this.jobField = false;
        this.clone = false;
        this.jobDetails = {};
      },
      searchJob() {
        this?.$refs?.form?.validate();
        if (this.$route.params.job_number === this.jobNumber) {
          this.$store.dispatch('setAlert', {
            status: 'error',
            show: true,
            message: 'Parent job cannot be cloned',
          });
        } else {
          if (this.jobNumber.length) {
            this.loading = true;
            this.$api.operations.getJobDetails(this.jobNumber)
              .then(response => {
                if (response?.operation?.["JOB ORDER NO."]) {
                  this.operation = response.operation;
                  if (!this.operation.e_sign) {
                    this.jobField = true;
                    this.clone = true;
                    this.jobDetails.agent = response.operation.AGENT;
                    this.jobDetails.driver = response.operation.DRIVER;
                    this.jobDetails.customer = response.operation.CUSTOMER;
                    this.jobDetails.pax = response.operation.PAX;
                    this.jobDetails.vessel = response.operation.VESSEL;
                  } else {
                    this.$store.dispatch('setAlert', {
                      status: 'error',
                      show: true,
                      message: 'The job number is already e-signed',
                    });
                  }
                } else {
                  this.$store.dispatch('setAlert', {
                    status: 'error',
                    show: true,
                    message: 'The job number does not exists',
                  });
                }
              })
              .catch(err => {
                this.$store.dispatch('setAlert', {
                  status: 'error',
                  show: true,
                  message: err.message,
                })
              }).finally(() => (this.loading = false));
          }
        }
      },
    },
    watch: {
      clonedJob(value) {
        this.cloneDetails.folder = value?.["FOLDER"];
        this.cloneDetails.signeeName = value?.["SIGNEE NAME"];
        this.cloneDetails.rank = value?.["RANK"];
        this.cloneDetails.remarks = value?.["REMARKS"];
        this.cloneDetails.time = value?.["JOB END TIME"];
        this.cloneDetails.eSign = value?.["e_sign"];
      },
    },
  };
</script>
<style>
  .row {
    margin: 0px;
  }
  .card {
    min-height: 145px;
  }
</style>