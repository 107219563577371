import Vue from 'vue';
import vuetify from '../src/plugins/vuetify';
import router from '../src/router';
import store from '../src/store';
import App from '../src/app.vue';
import * as services from '../src/services';
import DatetimePicker from 'vuetify-datetime-picker';

Vue.prototype.$api = services.default;
Vue.use(DatetimePicker);

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
    data: {
      pageTitle: '',
    },
    methods: {
      hasAccess(privilege) {
        if (this.$store.state.auth.profile.role === "admin") return true;

        return (this.$store.state.auth.profile.privileges || []).includes(privilege);
      },
    },
    beforeMount: function() {
      if (this !== this.$root) return;

      if (this?.$el?.outerHTML) {
        this.__TurbolinksAdapterOriginalOuterHTML__ = this.$el.outerHTML;
        var _this = this;
        document.addEventListener("turbolinks:before-cache", function teardown() {
          _this.$destroy();
          document.removeEventListener("turbolinks:before-cache", teardown);
        });
      }
    },
    destroyed: function() {
      if (!this.__TurbolinksAdapterOriginalOuterHTML__) return;

      if (this?.$el?.outerHTML) {
        this.$el.outerHTML = this.__TurbolinksAdapterOriginalOuterHTML__;
        delete this.__TurbolinksAdapterOriginalOuterHTML__;
      }
    },
  }).$mount();
  document.body.appendChild(app.$el);
});