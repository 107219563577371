<template>
  <div>
    <div v-if="!operation['JOB ORDER NO.']">
      <v-form ref="form" v-model="form" @submit.prevent="submit">
        <v-row class="justify-center pt-10">
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-text-field
              label="Job Number"
              placeholder="Enter Job Number"
              outlined
              dense
              type="text"
              :rules="[rules.required]"
              v-model="jobNumber"
              prepend-inner-icon="mdi-magnify"
              append-icon="mdi-send"
              @click:append="searchJob"
              :disabled="loading"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div v-else>
      <v-btn outlined color="primary" class="ma-4 no-print" @click="handleSearchJob">
        <v-icon>mdi-chevron-left</v-icon>
        Search Job
      </v-btn>
      <v-btn v-if="operation.e_sign" outlined color="primary" class="ma-4 no-print" @click="printReport">
        <v-icon>mdi-printer</v-icon>
        Print Report
      </v-btn>
      <v-btn v-if="operation.e_sign && $root.hasAccess('admin')" outlined color="primary" class="ma-4 no-print" :disabled="edit" @click="edit = true">
        <v-icon>mdi-pencil</v-icon>
        Edit Job
      </v-btn>
      <v-btn v-if="operation.e_sign && $root.hasAccess('admin')" outlined color="primary" class="ma-4 no-print" @click="dialogForm = {action: 'Job Clone Details', showForm: true, clonedJob: Object.assign({}, operation)}">
        <v-icon>mdi-content-copy</v-icon>
        Clone Job
      </v-btn>
      <div class="d-flex justify-center">
        <div class="text-center content-placeholder">
          <img
            class="d-inline-block logo-w"
            :src="TransAsiaLogo"
          />
          <div class="py-4">
            <div class="text-center">
              <div class="text-h6 font-weight-bold section-title">
                Job Report
              </div>
            </div>
          </div>
          <v-row class="text-left">
            <v-col cols="12" sm="6" class="d-flex" 
              v-for="(item, index) in Object.keys(operation)"
              :key="index" v-if="!displayFields.includes(item)"
            >
              <v-row class="job-row__border ma-1 align-center">
                <v-col cols="6" class="text-capitalize primary--text subtitle-2 font-weight-bold py-0">
                  {{item}}
                </v-col>
                <v-col cols="6" class="subtitle-2 py-0">
                  {{operation[item]}}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="justify-center mx-6" v-if="!operation.e_sign || (edit && $root.hasAccess('admin'))">
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                v-model="signeeName"
                label="Enter signee name"
                prepend-inner-icon="mdi-account"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                v-model="rank"
                label="Enter rank"
                prepend-inner-icon="mdi-format-align-middle"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" v-if="(edit && $root.hasAccess('admin'))">
              <v-dialog
                ref="dialog"
                v-model="modal2"
                :return-value.sync="time"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time"
                    label="Select Job End Time"
                    prepend-inner-icon="mdi-clock"
                    readonly
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="modal2"
                  v-model="time"
                  :format="'24h'"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(time)">OK</v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12">
              <div>
                <v-textarea
                label="Remarks"
                placeholder="Enter Remarks"
                outlined
                dense
                hide-details
                :rows="2"
                v-model="remarks"></v-textarea>
              </div>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="12" sm="6" md="4" lg="4">
              <div v-if="operation.e_sign">
                <h1>E-Sign</h1>
                <img
                  class="d-inline-block e-sign-img"
                  :src="operation.e_sign"
                />
                <div class="py-2" v-if="edit && $root.hasAccess('admin')">
                  <v-btn color="success" @click="saveEdit" :loading="saveLoading" :disabled="saveLoading">
                    Save
                  </v-btn>
                  <v-btn @click="edit = false" :disabled="saveLoading">
                    Cancel
                  </v-btn>
                </div>
              </div>
              <electronic-signture v-else @saveSign="saveJob($event)" :saveLoading="saveLoading" />
            </v-col>
          </v-row>
        </div>
        <clone-form
          :clonedJob="dialogForm.clonedJob"
          :action="dialogForm.action"
          :showForm="dialogForm.showForm"
          @closeDialog="resetDialog()" />
      </div>
    </div>
    <v-row class="justify-center">
      <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
        <div v-if="loading">
          <s-loading />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import rules from '../../utilities/validator';
import Loading from '../shared/Loading';
import axios from 'axios';
import ElectronicSignture from './blocks/ElectronicSignture';
import CloneForm from './blocks/CloneForm';
import TransAsiaLogo from 'images/transasia_logo.png';
import moment from 'moment';

export default {
  name: 'OperationShow',
  components: {
    's-loading': Loading,
    ElectronicSignture,
    CloneForm
  },
  data() {
    return {
      form: false,
      rules,
      jobNumber: '',
      loading: false,
      operation: {},
      saveLoading: false,
      TransAsiaLogo,
      remarks: '',
      signeeName: '',
      modal2: false,
      time: '',
      rank: '',
      edit: false,
      dialogForm: {
        showForm: false,
        action: '',
        clonedJob: {},
      },
    }
  },
  computed: {
    displayFields() {
      if (this.edit && this.$root.hasAccess('admin')) {
        return ['e_sign', 'RANK', 'REMARKS', 'JOB END TIME', 'SIGNEE NAME', 'id'];
      }
      return ['e_sign', 'id', 'CLAIM AMT / REMARKS'];
    }
  },
  methods: {
    printReport() {
      window.print();
    },
    resetDialog() {
      this.dialogForm = {showForm: false, action: '', clonedJob: {}};
    },
    handleSearchJob() {
      this.operation = {};
      this.jobNumber = moment().format('YYYYMMDD');
      this.$router.push({name: 'operation-show', params: {job_number: 'new'}});
    },
    saveJob(sign) {
      if (sign) {
        this.saveLoading = true;
        this.$api.operations.create({
          job_number: this.operation['JOB ORDER NO.'],
          e_sign: sign,
          remarks: this.remarks,
          rank: this.rank,
          end_time: this.time,
          signee_name: this.signeeName,
          start_time: this.operation['TIME'],
          driver: this.operation['DRIVER'],
          agent_name: this.operation['AGENT'],
          customer_name: this.operation['CUSTOMER'],
          vessel: this.operation['VESSEL']
        })
          .then(response => {
            this.$store.dispatch('setAlert', {
              show: true,
              message: 'Job Saved successfully',
            })
            if (this.$store.state.auth.profile.role === 'admin') {
              this.$router.push({name: 'operations'});
            } else {
              this.handleSearchJob();
            }
          })
          .catch(err => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            })
          }).finally(() => (this.saveLoading = false));
      }
    },
    saveEdit() {
      this.saveLoading = true;
      this.$api.operations.update({
        id: this.operation.id,
        remarks: this.remarks,
        rank: this.rank,
        end_time: this.time,
        signee_name: this.signeeName,
        start_time: this.operation['TIME'],
        driver: this.operation['DRIVER'],
        agent_name: this.operation['AGENT'],
        customer_name: this.operation['CUSTOMER'],
        vessel: this.operation['VESSEL']
      })
        .then(response => {
          this.$store.dispatch('setAlert', {
            show: true,
            message: 'Job Saved successfully',
          })
          if (this.$store.state.auth.profile.role === 'admin') {
            this.$router.push({name: 'operations'});
          } else {
            this.handleSearchJob();
          }
        })
        .catch(err => {
          this.$store.dispatch('setAlert', {
            status: 'error',
            show: true,
            message: err.message,
          })
        }).finally(() => (this.saveLoading = false));
    },
    searchJob() {
      this?.$refs?.form?.validate();

      if (this.jobNumber.length) {
        this.loading = true;
        this.$api.operations.getJobDetails(this.jobNumber)
          .then(response => {
            if (response?.operation?.["JOB ORDER NO."]) {
              if (this.$route.params.job_number === 'new') {
                this.$router.push({name: 'operation-show', params: {job_number: this.jobNumber}});
              }
              this.operation = response.operation;
              this.time = response?.operation?.["JOB END TIME"] || new Date().getHours() + ":" + new Date().getMinutes();
              this.rank = response?.operation?.["RANK"] || '';
              this.remarks = response?.operation?.["REMARKS"] || '';
              this.signeeName = response?.operation?.["SIGNEE NAME"] || '';
              const dateString = response?.operation?.["JOB ORDER NO."].substring(0, 8);
              const year = dateString.substring(0, 4);
              const month = dateString.substring(4, 6);
              const day = dateString.substring(6, 8);
              const date = year + '-' + month + '-' + day;
              this.operation["VOUCHER DATE"] = date || '';
            } else {
              this.$store.dispatch('setAlert', {
                status: 'error',
                show: true,
                message: 'The job number does not exists',
              });
            }
          })
          .catch(err => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            })
          }).finally(() => (this.loading = false));
      }
    },
  },
  created() {
    if (this.$route.params.job_number !== 'new') {
      this.jobNumber = this.$route.params.job_number;
      this.time = new Date().getHours() + ":" + new Date().getMinutes();
      this.searchJob();
    } else {
      this.jobNumber = moment().format('YYYYMMDD');
    }
  }
}
</script>

<style scoped>
  >>> .v-time-picker-clock__item--active {
    color: #273978;
  }
  .content-placeholder {
    max-width: 837px;
    border: 1px solid #273978;
    margin: 48px;
    box-decoration-break: clone;
  }
  @media (max-width: 600px) {
    .content-placeholder {
      max-width: 837px;
      border: 1px solid #273978;
      margin: 4px;
      box-decoration-break: clone;
    }
    .logo-w {
      width: 90%;
      object-fit: contain;
    }
  }
  .logo-w {
    width: 90%;
    object-fit: contain;
  }
  .e-sign-img {
    max-height: 200px;
  }
  .section-title {
    background-color: #e6e6e6 !important;
    padding: 4px;
  }
  @media print {
    @page {
      size: auto;
      margin: 0px;
      print-color-adjust: exact;
      -webkit-print-color-adjust: exact;
    }
    @media (min-width: 100px) {
      >>> .col-sm-6 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
      }
      >>> .col-12 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
      }
      .section-title {
        background-color: #e6e6e6 !important;
        padding: 4px;
      }
      .content-placeholder {
        border: none;
        margin: 20px;
      }
    }
  }
</style>