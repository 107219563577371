<template>
  <v-container fluid>
    <v-row class="pt-10 mt-10">
      <v-col cols="12" align="center">
        <v-progress-circular
          :size="70"
          :width="5"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 's-global-loading',
  };
</script>