<template>
  <v-app>
    <v-main>
      <div v-if="isLoggedIn" class="no-print">
        <s-appbar />
        <div class="padding-height full-width"></div>
      </div>
      <div v-cloak class="full-height">
        <div v-if="isLoading">
          <s-loading />
        </div>
        <router-view />
      </div>
      <v-snackbar v-model="show" :color="status" :timeout="6000" bottom right>
        {{message}}
        <template v-slot:action="{ attrs }">
          <v-btn icon class="white--text" v-bind="attrs"
            @click="setAlert({status: '', show: false, message: ''})">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import Loading from './components/shared/Loading';
  import AppBar from './components/shared/AppBar';

  export default {
    name: 'App',
    components: {
      's-loading': Loading,
      's-appbar': AppBar,
    },
    computed: {
      ...mapState({
        status: state => state.alert.status,
        message: state => state.alert.message,
        isLoading: state => state.isLoading,
        isLoggedIn: state => state.auth.profile.name,
      }),
      show: {
        get() {
          return this.$store.state.alert.show;
        },
        set(val) {
          this.$store.dispatch('setAlert', { status: '', show: val, message: '' });
        },
      },
    },
    methods: {
      ...mapActions([
        'setAlert',
      ]),
    },
  };
</script>

<style>
  .full-width {
    width: 100% !important;
  }
  .full-height {
    height: 100% !important;
  }
  .padding-height {
    height: 70px !important;
  }
  >>> .v-btn {
    text-transform: none !important;
  }
  @media print {
    html, body {
      width: 210mm;
      height: 297mm;
    }
    * {
      color-adjust: exact !important;
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }
    .no-print, .no-print * {
      display: none !important;
    }
    @page {
      size: A4;
      margin: 0px;
      print-color-adjust: exact;
      -webkit-print-color-adjust: exact;
    }
    @media (min-width: 100px) {
      >>> .col-sm-6 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
      }
    }
  }
</style>