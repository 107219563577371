import http from '../axios';

export function index() {
  return http.get('/users');
}

export function update(payload) {
  return http.patch(`/users/${payload.id}`, payload);
}

export function updatePassword(payload) {
  return http.patch('/users/update_password', payload);
}

export function getProfile() {
  return http.get('/users/get_profile');
}

export function create(payload){
  return http.post('/users', payload);
}