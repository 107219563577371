<template>
  <v-container fluid>
    <v-row class="pb-4">
      <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="dates"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Select date range"
              prepend-inner-icon="mdi-calendar"
              readonly
              outlined
              hide-details
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            id="dateRangePicker"
            v-model="dates"
            range
            @change="applyFilter({date_range: dateRangeText})"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(dates)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
        <v-select
          label="Filter by customer"
          placeholder="Filter by customer"
          outlined
          dense
          hide-details
          v-model="customerName"
          prepend-inner-icon="mdi-account"
          :items="customerNames"
          @change="applyFilter({customer_name: $event})"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
        <v-select
          label="Filter by driver"
          placeholder="Filter by driver"
          outlined
          dense
          hide-details
          v-model="driver"
          prepend-inner-icon="mdi-account"
          :items="drivers"
          @change="applyFilter({driver: $event})"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
        <v-select
          label="Filter by vessel"
          placeholder="Filter by vessel"
          outlined
          hide-details
          dense
          v-model="vessel"
          prepend-inner-icon="mdi-ferry"
          :items="vessels"
          @change="applyFilter({vessel: $event})"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
        <v-select
          label="Filter by folder"
          placeholder="Filter by folder"
          outlined
          hide-details
          dense
          v-model="allFolder"
          prepend-inner-icon="mdi-folder"
          :items="allFolders"
          @change="applyFilter({folder: $event})"
        ></v-select>
      </v-col>
    </v-row>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab bottom right fixed color="primary"
          v-bind="attrs" v-on="on" @click="$router.push({name: 'operation-show', params: {job_number: 'new'}})">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
      <span>Search New Job</span>
    </v-tooltip>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          :no-data-text="'No data found'"
          :no-results-text="'No results found'"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
        <template v-slot:item.job_number="{ item }">
          <v-btn @click="$router.push({name: 'operation-show', params: {job_number: item.job_number}})">
            <span><u>{{item.job_number}}</u></span>
          </v-btn>
        </template>
        <template v-slot:item.folder="{ item }">
          <v-autocomplete
            placeholder="Select the Folder"
            variant="plain"
            density="compact"
            class="folder-autocomplete"
            menu-props="closeOnContentClick"
            dense
            hide-details
            :search-input.sync="searchFolder[item.id]"
            :items="folders"
            v-model="item.folder"
            :loading="loading"
            @change="putFolder(item, $event)"
          >
            <template v-slot:no-data>
              <span class="text-caption px-1">
                create new folder
                <v-btn icon outlined x-small @click="putFolder(item, searchFolder[item.id])" color="success">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </span>
            </template>
          </v-autocomplete>
        </template>
        <template v-slot:item.created_at="{ item }">
          <span>{{item.created_at | formatDate}}</span>
        </template>
        <template v-slot:item.voucher_date="{ item }">
          <nobr>{{getFormattedVoucherDate(item.job_number)}}</nobr>
        </template>
        <template v-slot:item.billed="{ item }">
          <span v-if="item.billed">
            Billed
          </span>
          <span v-else>
            <v-btn
              small
              color="primary" 
              @click="markAsBilled(item)"
              :disabled="btnLoading[item.id]"
              :loading="btnLoading[item.id]"
            >
              Mark as billed
            </v-btn>
          </span>
        </template>
        <template v-slot:progress>
          <div class="full-width text-center ma-4">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="60"
              :width="5"
            ></v-progress-circular>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment';

  export default {
    name: 's-operations',
    data() {
      return {
        search: '',
        loading: false,
        searchFolder: {},
        folders: [],
        btnLoading: {},
        folderLoading: {},
        headers: [
          {text: 'Id', value: 'id', align: 'start'},
          {text: 'Job Number', value: 'job_number', align: 'center'},
          {text: 'Folder', value: 'folder', align: 'center'},
          {text: 'Driver', value: 'driver', align: 'center'},
          {text: 'Agent', value: 'agent_name', align: 'center'},
          {text: 'Customer', value: 'customer_name', align: 'center'},
          {text: 'Vessel', value: 'vessel', align: 'center'},
          {text: 'Signee Name', value: 'signee_name', align: 'center'},
          {text: 'Rank', value: 'rank', align: 'center'},
          {text: 'Job Start Time', value: 'start_time', align: 'center'},
          {text: 'Job End Time', value: 'end_time', align: 'center'},
          {text: 'Voucher Date', value: 'voucher_date', align: 'center'},
          {text: 'Billed Status', value: 'billed', align: 'center'}
        ],
        items: [],
        customerName: 'All',
        driver: 'All',
        vessel: 'All',
        allFolder: 'All',
        allFolders: [],
        customerNames: [],
        drivers: [],
        vessels: [],
        modal: false,
        dates: [moment(Date.now() - 3 * 24 * 3600 * 1000).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        filters: {}
      };
    },
    computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
    filters: {
      formatDate(date) {
        return moment(date).format('lll');
      }
    },
    methods: {
      getFormattedVoucherDate(job_number) {
        const dateString = job_number.substring(0, 8);
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);
        const date = year + '-' + month + '-' + day;
        return date || '';
      },
      markAsBilled(item) {
        this.btnLoading[item.id] = true;
        this.$api.operations.update({...item, billed: true})
        .then(response => {
          this.$store.dispatch('setAlert', {
            show: true,
            message: 'Job marked as billed successfully',
          })
          item.billed = true;
        })
        .catch(err => {
          this.$store.dispatch('setAlert', {
            status: 'error',
            show: true,
            message: err.message,
          })
        }).finally(() => (this.btnLoading[item.id] = false));
      },
      putFolder(item, folderName) {
        this.folderLoading[item.id] = true;
        this.$api.operations.update({...item, folder: folderName})
          .then((response) => {
            this.getAllFolders().then(() => {
              this.searchFolder[item.id] = folderName;
              item.folder = folderName;
            });
          }).catch((err) => {0
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.folderLoading[item.id] = false));
      },
      applyFilter(filterObj) {
        this.filters = Object.assign(this.filters, filterObj);
        this.getOperations(this.filters);
      },
      getOperations() {
        this.loading = true;
        this.$api.operations.index(this.filters)
          .then((response) => {
            this.items = response.operations;
            this.customerNames = ['All', ...response.customer_names];
            this.drivers = ['All', ...response.drivers];
            this.vessels = ['All', ...response.vessels];
            this.loading = false;
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      getAllFolders() {
        return this.$api.operations.getFolderDetails()
          .then((response) => {
            if(response.folders.length) {
              this.folders = response.folders;
              this.allFolders = ['All', ...response.folders];
            }
          }).catch((err) => {0
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      }
    },
    created() {
      if (!(this.$store.state.auth.profile.role === 'admin')) {
        this.$router.push({name: 'operation-show', params: {job_number: 'new'}});
        this.$store.dispatch('setAlert', {
          status: 'error',
          show: true,
          message: 'Please contact Admin to access this page',
        });
        return;
      }
      this.filters = {
        date_range: this.dateRangeText,
        customer_name: 'All',
        vessel: 'All',
        driver: 'All',
        folder: 'All'
      };
      if (this.$route.query.folderName) {
        this.allFolder = this.$route.query.folderName;
        this.applyFilter({folder: this.$route.query.folderName});
      }
      this.getOperations(this.filters);
      this.getAllFolders();
    },
  };
</script>
<style>
  #dateRangePicker {
    .v-btn--active {
      background-color: #d32423 !important;
      border-color: #d32423 !important;
    }
  }
  .folder-autocomplete {
    min-width: 100px;
  }
</style>