import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Login from '../components/public/Login';
import Home from '../components/dashboard';
import Profile from '../components/users/Profile';
import User from '../components/users';
import Operation from '../components/operations';
import OperationShow from '../components/operations/Show';
import Folder from '../components/folders';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {public: true}
    },
    {
      path: '/',
      name: 'dashboard',
      component: Home,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
    },
    {
      path: '/users',
      name: 'users',
      component: User,
    },
    {
      path: '/operations',
      name: 'operations',
      component: Operation
    },
    {
      path: '/operations/:job_number',
      name: 'operation-show',
      component: OperationShow
    },
    {
      path: '/folders',
      name: 'folders',
      component: Folder
    },
  ],
});

// sync routes with auth module
store.dispatch('auth/syncRouter', router);

export default router;
