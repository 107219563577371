<template>
  <v-container fluid fill-height>
    <v-row justify="space-around" class="justify-center">
      <v-col cols="12" sm="6" md="6" lg="6" align-self="center" class="text-center mb-12">
        <v-img class="d-inline-block" width="80%" :src="TransAsiaLogo" />
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" class="text-center">
        <v-card class="width-login d-inline-block" rounded>
          <v-card-title class="text-h5 font-weight-regular primary white--text">
            Login
          </v-card-title>
          <v-form ref="form" v-model="form" @submit.prevent="submit">
            <v-card-text class="mt-5 pb-0">
              <v-text-field
                label="Username"
                placeholder="Enter Username"
                :rules="[rules.required]"
                outlined
                v-model="loginForm.username"
                prepend-inner-icon="mdi-account-key"
                clearable
              ></v-text-field>
              <v-text-field
                label="Password"
                placeholder="Enter Password"
                :rules="[rules.required, rules.password]"
                outlined
                counter="15"
                v-model="loginForm.password"
                prepend-inner-icon="mdi-key"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
                clearable
              ></v-text-field>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn color="primary" type="submit"
                :loading="loading" :disabled="loading" @click="loginClick">Submit</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import rules from '../../utilities/validator';
  import TransAsiaLogo from 'images/transasia_logo.png';

  export default {
    name: 's-login-page',
    data() {
      return {
        showPassword: false,
        TransAsiaLogo,
        rules,
        form: false,
        loginForm: {
          username: '',
          password: '',
        },
        loading: false,
      };
    },
    methods: {
      submit() {
        return this.$refs.form.validate();
      },
      loginClick() {
        if(!this.submit()) return;
        const self = this;
        this.loading = true;
        this.$api.sessions.login(this.loginForm)
          .then((response) => {
            this.loading = false;
            this.$store.dispatch('auth/setProfile', response.profile);
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Logged in successfully',
            });
            if (response?.profile?.role === 'admin') {
              this.$router.push({name: 'operations'});
            } else {
              this.$router.push({name: 'operation-show', params: {job_number: 'new'}});
            }
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
    },
  };
</script>

<style scoped>
  .width-login {
    width: 300px;
  }
</style>